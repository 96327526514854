/* styles.css */
@import 'tailwindcss/base.css';
/* Base styles */
@import 'tailwindcss/components.css';
/* Component styles */
@import 'tailwindcss/utilities.css';
/* Utility classes */
.brand-container {
  font-family: "roboto";
}
.category-container {
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  color: rgba(32, 32, 32, 0.65);
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 15px;
}
.category-box {
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  color: rgba(32, 32, 32, 0.65);
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}
.brand-category {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f56b3;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 6.5px 8px;
  gap: 12px;
  cursor: pointer;
}
.brand-category-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0f56b3;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 6.5px 8px;
  gap: 12px;
  cursor: pointer;
}
.brand-category:hover {
  background-color: #0f56b3;
  color: white;
}
.sub-category-container {
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  color: rgba(32, 32, 32, 0.65);
  gap: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}
.cat-item {
  display: flex;
  align-items: center;
  padding: 5px;
}
.cat-item span {
  margin-top: 1px;
  cursor: default;
}
.add-container {
  position: relative;
  bottom: 0;
  display: flex;
  margin-top: 20px;
  justify-content: left;
}
.add-category-btn {
  width: 164px;
  height: 43px;
  background-color: #0f56b3;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}
.add-category-btn:hover {
  transform: scale(1.05);
}
.remove-category-btn {
  width: 164px;
  height: 43px;
  background-color: #ff6464;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin-left: 15px;
}
.remove-category-btn:hover {
  transform: scale(1.05);
}

.category-body {
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  flex-direction: row;
  color: rgba(32, 32, 32, 0.65);
  gap: 23px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.cat-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 140px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}
.cat-card:hover {
  transform: scale(1.02);
}
.cat-card-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 140px;
  background-color: white;
  border-radius: 10px;
}
.cat-brand-img {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.cat-brand-title {
  flex: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
}
.cat-brand-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cat-brand-title-name {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #101010;
}
.modal-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #0f56b3;
  color: white;
  cursor: pointer;
  font-weight: 500;
}
.brand-cart-delete {
  background-color: #ff6464;
  height: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
}
.brand-cart-delete i {
  padding: 10px;
}
.my-file-upload {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .category-body {
    justify-content: center;
  }
  .brand-category {
    padding: 3.5px 6px;
    gap: 4px;
  }
  .cat-brand-title-name {
    font-size: 12px;
  }
  .category-body {
    gap: 15px;
  }
  .cat-brand-img {
    padding: 5px;
  }
  .cat-brand-desc {
    font-size: 9px;
  }
  .remove-category-btn {
    margin-left: 5px;
  }
  .add-container {
    left: 0px;
    justify-content: space-between;
  }
  .cat-card {
    width: 150px;
    height: 70px;
  }
  .cat-card-div {
    width: 150px;
    height: 70px;
  }
}
