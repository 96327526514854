/* styles.css */
@import 'tailwindcss/base.css';
/* Base styles */
@import 'tailwindcss/components.css';
/* Component styles */
@import 'tailwindcss/utilities.css';
/* Utility classes */
.scrollbar {
  overflow-y: auto;
}
.scrollbar::-webkit-scrollbar {
  width: 2px;
  border-radius: 2px;
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: gray;
}

#style-1::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(146, 146, 146, 0.3);
  background-color: rgba(177, 177, 177, 0.507);
}
.social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 15px;
  margin-top: 15px;
  font-family: "roboto";
}
.social-container-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 300px;
  background-color: white;
  border: 1px solid rgb(201, 201, 201);
  border-radius: 5px;
  flex-direction: column;
  cursor: pointer;
}
.social-container-item:hover {
  transform: scale(1.01);
}
.social-container-item-title {
  font-size: 20px;
}
.social-item-container {
  max-height: 600px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-direction: column;
  margin-top: 15px;
  overflow: auto;
  font-family: "roboto";
}
.social-item-container-item {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}
.social-item-container-item-img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  padding: 5px;
  width: 10%;
}
.social-item-container-item-div1 {
  padding: 5px;
  border-left: 1px solid #a7a7a7;
  width: 10%;
}
.social-item-container-item-div2 {
  padding: 5px;
  border-left: 1px solid #a7a7a7;
  width: 20%;
}
.social-item-container-item-div3 {
  padding: 5px;
  border-left: 1px solid #a7a7a7;
  width: 60%;
}
.about-title {
  font-size: 18px;
  line-height: 1.5;
  font-family: Inter;
  text-transform: uppercase;
  font-weight: 600;
}
.jodit-wysiwyg {
  background-color: white;
}
.my-width-heith {
  width: 150px !important;
  height: 150px !important;
}
.vertical-center {
  vertical-align: middle !important;
}
.footer-hover:hover {
  transform: scale(1.1);
  transition: ease-in 0.3s;
}
.brand-hover:hover {
  transform: scale(0.98);
  transition: ease-in 0.3s;
}
.m-shiidel-hover {
  height: 28%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #244583;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  font-family: "roboto";
}
.m-shiidel-hover1 {
  height: 28%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  justify-content: center;
  align-items: center;
  font-family: "roboto";
  line-height: 1;
}
.m-shiidel-text {
  font-size: 40px;
  color: white;
  opacity: 1;
  font-weight: bold;
}
.m-shiidel-text2 {
  font-size: 20px;
  color: white;
  opacity: 1;
  font-weight: 500;
}
.search-filter {
  width: 20%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.search-pruduct {
  width: 80%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.search-pruduct2 {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.search-pruduct-item {
  width: 230px;
  height: 150px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  padding: 5px;
}
.search-pruduct-item2 {
  width: 230px;
  height: 170px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e5e5e5;
  padding: 5px;
  overflow: hidden;
  cursor: pointer;
}
.search-pruduct-item:hover .search-btn {
  display: flex;
}
.search-contaner {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 15px;
}

.search-btn {
  margin-top: 100px;
  position: absolute;
  border: none;
  align-items: center;
  justify-content: center;
  background-color: #ff5722;
  width: 150px;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: none;
}
.search-btn:hover {
  box-shadow: 1px 1px 10px #ffffff;
}
.detail-btn {
  font-size: 15px;
  background-color: white;
  color: black;
  width: 160px;
  text-align: center;
  cursor: pointer;
  padding: 6px;
  border-radius: 5px;
}
[data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  justify-content: center;
}
.main-menu {
  flex: 1 0 20%;
  margin-bottom: 8px;
  margin-top: 8px;
  margin-right: 8px;
  cursor: pointer;
}
.main-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
  font-size: 16px;
  color: #808080;
}
.scrollmenu {
  overflow-y: auto;
}

.scrollmenu::-webkit-scrollmenu {
  width: 5px;
  background-color: transparent;
}

.scrollmenu::-webkit-scrollmenu-thumb {
  border-radius: 10px;
  background-color: rgba(224, 224, 224, 0.2);
}
.main-menu.active {
  color: cornflowerblue;
}
.slick-track {
  text-align: -webkit-center;
}
.service-item {
  width: 600px;
  text-align: center;
  height: 320px;
}
.service-item-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.service-item:hover {
  transform: scale(0.98);
  transition: all 0.3s;
}
@media only screen and (max-width: 1050px) {
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12px;
    color: #808080;
    gap: 8px;
    padding: 5px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 900px) {
  .main-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5px;
    font-size: 12px;
  }
  .m-shiidel-text {
    font-size: 20px;
  }
  .m-shiidel-text2 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12px;
    color: #808080;
    gap: 8px;
    padding: 5px;
    margin-top: 0px;
  }
  .main-menu {
    margin-bottom: 0px;
    margin-top: 5px;
    width: 100%;
    color: black;
    font-size: 14px;
  }
  .search-filter {
    display: none;
  }
  .search-pruduct {
    width: 100%;
    justify-content: center;
    gap: 35px;
  }
  .search-pruduct-item {
    width: 150px;
    height: 100px;
  }
  .m-shiidel-text {
    font-size: 10px;
  }
  .m-shiidel-text2 {
    font-size: 8px;
  }
  .my-font-size {
    font-size: 8px;
  }
  .about-mobile-gap {
    gap: 20px;
  }
  .about-mobile-width {
    width: 90%;
  }
  .social-item-container-item-img {
    width: 30%;
  }
  .social-item-container-item-div1 {
    display: none;
  }
  .social-item-container-item-div2 {
    width: 70%;
  }
  .social-item-container-item-div3 {
    display: none;
  }
}
