/* styles.css */
@import 'tailwindcss/base.css';
/* Base styles */
@import 'tailwindcss/components.css';
/* Component styles */
@import 'tailwindcss/utilities.css';
/* Utility classes */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-btn:hover {
  background-color: white;
  transition: 0.5s;
  color: black;
}
