/* styles.css */
@import 'tailwindcss/base.css';
/* Base styles */
@import 'tailwindcss/components.css';
/* Component styles */
@import 'tailwindcss/utilities.css';

:root {
    --swiper-theme-color: #fff !important;
}

body {
    position: relative;
    height: 100%;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: rgb(224, 208, 208);

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Utility classes */
.social-icon__link {
    font-size: 2rem;
    display: inline-block;
    transition: 0.5s;
}

.social-icon__link:hover {
    transform: translateY(-10px);
}